* {
    margin: 0;
    padding: 0;
}

#accordian h3 a {
    padding: 0 10px;
    font-size: 12px;
    line-height: 34px;
    display: block;
    color: black;
    text-decoration: none;
}

#accordian h3:hover {
    text-shadow: 0 0 1px rgba(255, 255, 255, 0.7);
}

i {
    margin-right: 10px;
}

#accordian li {
    list-style-type: none;
}

#accordian ul ul li a,
#accordian h4 {
    color: black;
    text-decoration: none;
    font-size: 11px;
    line-height: 27px;
    display: block;
    padding: 0 15px;
    -webkit-transition: all 0.15s;
    transition: all 0.15s;
    position: relative;
}

#accordian ul ul li a:hover {
    border-left: 5px solid black;
}

#accordian ul ul {
    display: none;
}

#accordian li.active>ul {
    display: block;
}

#accordian ul ul ul {
    margin-left: 15px;
    border-left: 1px dotted rgba(0, 0, 0, 0.5);
}

#accordian a:not(:only-child):after {
    content: "\f104";
    font-family: fontawesome;
    position: absolute;
    right: 10px;
    top: 0;
    font-size: 14px;
}

#accordian .active>a:not(:only-child):after {
    content: "\f107";
}