.vert {
    background-color : Green !important;
    background-image :none !important;
    color: White !important;
    font-weight:bold !important;
    font-size: 12pt;
 }
 .bleu {
   background-color : blue !important;
   background-image :none !important;
   color: White !important;
   font-weight:bold !important;
   font-size: 12pt;
}
 .orange {
    background-color : orange !important;
    background-image :none !important;
    color: White !important;
    font-weight:bold !important;
    font-size: 12pt;
 }
 .rouge {
    background-color : red !important;
    background-image :none !important;
    color: White !important;
    font-weight:bold !important;
    font-size: 12pt;
 }
 .pagination {
   justify-content: center;
}
.ui-tabs .ui-tabs-nav li a {font-size:10pt !important;}
.dropdown-menu > a:hover, .dropdown-menu > a:focus {
   background-color:gray;
}
.ui-tabs-panel {
   overflow-y: hidden;
}
body{
   overflow-x: hidden;
}