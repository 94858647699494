@import "~@fortawesome/fontawesome-free/scss/fontawesome";
$body-color: black;
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css";
@import "~bootstrap-select/dist/css/bootstrap-select.min.css";
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "~jquery-ui/themes/base/core.css";
@import "~jquery-ui/themes/base/theme.css";
@import "~jquery-ui/themes/base/autocomplete.css";
@import "~jquery-ui/themes/base/menu.css";
@import "~jquery-ui/themes/base/tabs.css";
@import "accordian.css";
@import "app.css";




